@import '../../../styles/colors.scss';
@import "../../../styles/mixin";

.uiServiceDoc {
  margin-top: 15px;

  &_title {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Source Sans Pro';
    margin-bottom: 0;
    @include media-min("tablet") {
      font-size: 14px;
  }
  }

  &_name {
    font-size: 10px;
    font-weight: 400;
    font-family: 'Source Sans Pro';
    color: $blue-menu;
    text-decoration: underline;
    margin-left: 15px;
    @include media-min("tablet") {
      font-size: 12px;
  }
  }
}
