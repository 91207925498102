@import '../../styles/colors.scss';
@import '../../styles/mixin';

.reservation {
  min-height: 30px;
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &_icon {
    width: 14px;
    margin-right: 15px;
  }

  &_text {
    width: 100%;
    min-height: 30px;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e2e2e2;
    border-radius: 5px;
  }

  &_desc {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    text-align: left;
    padding: 5px 10px;
  }

  &_hour {
    font-style: italic;
    font-size: 9px;
    color: #0f326e;
    margin-bottom: 5px;
    @include media-min('tablet') {
      font-size: 11px;
    }
  }

  &_title {
    font-size: 10px;
    font-weight: 600;
    color: $black;
    margin-top: 2px;
    @include media-min('tablet') {
      font-size: 12px;
    }
  }

  &_infocontainer {
    width: 30px;
  }

  &_info {
    position: relative;
    top: 3px;
  }

  &_close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.reservationOpen {
  padding: 0 10px 10px;

  &_label {
    font-weight: 500;
    margin-bottom: 10px;
  }

  &_date {
    font-weight: 500;
    margin-bottom: 10px;
    font-style: italic;
  }

  &_list {
    font-size: 12px;
  }
}

.close {
  width: 40px;
  height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.2);

  background: rgba(72, 81, 86, 0.5);
  border-radius: 5px;

  &_img {
    position: relative;
    top: 1px;
    width: 25px;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.stripeTicketLink {
  text-decoration: none;
  color: white;
}
