@import '../../../styles/colors.scss';
@import "../../../styles/mixin";

.uiServiceWeb {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &_title {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Source Sans Pro';
    @include media-min("tablet") {
      font-size: 14px;
  }
  }

  &_url {
    font-size: 10px;
    font-weight: 400;
    font-family: 'Source Sans Pro';
    @include media-min("tablet") {
      font-size: 12px;
  }

    a {
      text-decoration: underline;
      color: $blue-menu;
    }
  }
}
