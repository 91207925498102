@import '../../../styles/colors.scss';
@import "../../../styles/mixin";

.uiInput {
  border-radius: 6px;
  display: inline-block;
  text-align: left;
  width: 100%;

  &_label {
    width: max-content;
    padding: 0px 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 7px;
    color: $black;
    position: relative;
    top: 7px;
    left: 15px;
    background-color: $white;
    @include media-min("tablet") {
      font-size: 9px;
    }
  }

  &_field {
    box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.2);
    background-color: $white;
    background: $white;
    width: 260px;
    height: 42px;
    font-size: 16px;
    border: 1px solid $black;
    border-radius: 6px;
    padding-left: 15px;
    outline: none;
    color: $gray-input;
    @include media-min("tablet") {
      font-size: 18px;
    }

    &::placeholder {
      font-size: 13px;
      color: $gray;
      @include media-min("tablet") {
        font-size: 15px;
      }
    }

    &:focus {
      border: 1px solid $blue-actif;
      box-shadow: 0px 2px 4px rgba(50, 160, 230, 0.1);
      width: 93%;
    }

    &--full {
      width: 95%;
    }

    &--valid {
      border-color: $green;
    }
    &--error {
      border-color: $red;
    }

    &--secondary {
      color: $gray-input;
      opacity: 0.8;
      box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.1);
      border: none;
    }

    &::-webkit-datetime-edit {
      width: 100%;
    }

    &::-webkit-datetime-edit-text {
      padding: 0 0.3em;
    }

    &::-webkit-datetime-edit-fields-wrapper {
      width: 100%;
      height: 100%;
    }

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  &_icon {
    width: 0px;
    height: 0px;
    position: relative;
    top: -30px;
    left: 90%;
  }

  &_valid {
    color: $green;
  }

  &_error {
    color: $red;
  }
}
