$transparent: #00000000;
$red: #e73331;
$green: #BCCF00;
$gray-light: #e2e2e2;
$gray: #9b938d;
$gray-input: #313131;
$gray-secondary : #FAFAFA;
$black: #292929;
$white: #ffffff;
$blue-actif: #32a0e6;
$gray-textArea: #cdcdcd;
$blue-menu: #00afa0;
$dark-blue: #1155A8;
$dark-green: #8ba800;
$orange: #EFA800;
