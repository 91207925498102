@import "../../../styles/colors.scss";

.chevronButton {
    display: flex;
    align-items: center;
    padding: 10px 0;
    h1 {
        margin-right: 10px;
    }

    &--true {
        transform: rotate(270deg);
    }

    &--false {
        transform: rotate(180deg);
    }
}
