@import "../../../styles/colors.scss";
@import "../../../styles/mixin";
.uiDisponibilityCalendar {
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  &_date {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px;
    margin-bottom: 15px;
  }

  &_selectContainer {
    padding: 10px;
  }

  &_select {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-width: 100px;
    height: 30px;
    background-color: white;
    border-color: transparent;
    padding: 0 5px;
    @include media-min("tablet") {
      min-width: 120px;
      height: 50px;
  }
  }

  &_timeSlot {
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    height: 5vh;
    width: 26vw;
    margin: 5px;
    font-size: 14px;
    @include media-min("tablet") {
      height: 6vh;
      width: 27vw;
      font-size: 16px;
  }

    &_disabled {
      background-color: rgba(72, 81, 86, 0.15);
      color: black;
    }
  }

  &_timeSlot_active {
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    height: 5vh;
    width: 26vw;
    margin: 5px;
    background-color: yellowgreen;
    @include media-min("tablet") {
      height: 6vh;
      width: 27vw;
  }
  }

  &_timeSlotContainer {
    display: flex;
    flex: 30% 0;
  }
}
