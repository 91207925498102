@import "../../styles/colors.scss";
@import "../../styles/mixin";
.personalInformations {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 35px;

  &_title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    color: $black;
    @include media-min("tablet") {
      font-size: 16px;
  }
  }

  &_input {
    margin-top: 20px;
    margin-bottom: 10px;
    height: 40px;
  }

  &_error {
    text-align: center;
    padding-top: 10px;
    color: red;
    font-size: 14px;
    @include media-min("tablet") {
      font-size: 16px;
  }
  }

  &_submitOk {
    text-align: center;
    padding-top: 10px;
    color: green;
    font-size: 14px;
    @include media-min("tablet") {
      font-size: 16px;
  }
  }
}
.submit_button{
  margin-top: 20px;
}
