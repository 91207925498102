.layoutModal {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 16px;
  padding-right: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
  will-change: opacity;
  transition: opacity 0.3s ease;

  &-hide {
    bottom: auto;
    opacity: 0;
  }

  &_overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    cursor: pointer;
  }

  &_container {
    position: absolute;
    z-index: 1;
    left: 0;
    max-width: calc(100% - 96px);
    margin-left: 50%;
    margin-right: -50%;
    padding: 15px 0;
    will-change: top, margin-top;
  }

  &_content {
    position: relative;
    left: -50%;
    background-color: #fff;
    border-radius: 3px;
  }

  &_closeContainer {
    position: absolute;
    float: right;
    top: 0;
    right: -38px;
    width: 30px;
  }

  &_close {
    position: fixed;
    top: auto;
    right: auto;
    width: 30px;
    height: 30px;
    background-color: #fff;
    display: flex;
    vertical-align: center;
    align-items: center;
    justify-content: center;
  }
}

.layoutModal_content {
  padding: 11px;
}
