@import '../../styles/colors.scss';
@import "../../styles/mixin";

.forgotPassword {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
    height: 100vh;
  &_circleBackground {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 45vh;
  position:relative;
    &--green {
      width: 90vw;
      height: 90vw;
      border-radius: 50%;
      background:radial-gradient(circle at 100% 18%, rgba(253, 255, 255, 1) 0%, rgba(231, 243, 104, 1) 100%);
      filter: blur(10px);
      position: absolute;
      top: 14vh;
      left: -57vw;
    }
    &--red {
      width: 90vw;
      height: 90vw;
      border-radius: 50%;
      background:radial-gradient(circle at 48% 100%, rgba(253, 255, 255, 1) 0%, rgba(229, 43, 43, 1) 100%);
      mix-blend-mode: darken;
      filter: blur(10px);
      opacity: 0.8;
      position: absolute;
      z-index: 1;
    }
    &_transparent {
      width: 160vw;
      height: 160vw;
      border-radius: 50%;
      background-color: $white;
      opacity: 0.3;
      position: absolute;
      top: 18vh;
      z-index: 2;
      left: -65vw;
    }
  }
}

.secondContainerForgot {
  width: 100%;
  background: linear-gradient(to top, white 66%, transparent);
  background:  -webkit-linear-gradient(to top, white 66%, transparent);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 15px;
  z-index: 5;
  height: 55vh;
  top: -15vh;
}


.forgotPasswordContainer {
  @media (max-height: 400px) {
    margin-top: 3vh;
}
display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 3;
    top: 7vh;
    animation-name: AppearOpacity;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    .logoLogifirm-login {
      width: 68px;
      height: auto;
      margin-bottom: 10vh;
      @include media-min("tablet") {
        width: 75px;
      }
    }
}

.resetChoice {
  display: flex;
    flex-direction: column;
    align-items: center;
  &_text {
    text-align: center;
    margin-bottom: 35px;
    width: 60%;
    color: $black;
  }

  &_radioButtons {
    margin-bottom: 35px;
  }

  &_buttons {
    width: 95vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    @include media-min("tablet") {
      height: 150px;
    }
  }
}

.forgotPasswordContainer {
  margin-top: 5vh;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  animation-name: AppearOpacity;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  &_back {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.logoLogifirm-forgotPassword {
  position: relative;
  width: 68px;
  height: auto;
  margin-bottom: 10vh;
}

.resetEmail {
  display: flex;
  flex-direction: column;
  height: 50vh;
  align-items: center;
  width: 95%;

  &_input {
    padding: 15px 0;
    position: relative;
    width: 100%;
  }
  &_text {
    text-align: center;
    width: 70%;
    color: $black;
    line-height: 20px;
    @include media-min("tablet") {
      font-size: 14px;
    }
  }
}

@keyframes AppearOpacity {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
