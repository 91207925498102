@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import './colors.scss';
@import './helpers.scss';
@import './text.scss';
@import './mixin.scss';

.app {
  margin: 0;
  padding: 0;
  border: none;
  line-height: normal;
  font-family: $fontFamily-main;
  overflow: hidden;
  color: #0f326e;
}

body {
  margin: 0;
  padding: 0;
}

p {
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  padding: 0;
  @include media-min("tablet") {
    font-size: 14px;
  }
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  padding: 0;
  @include media-min("tablet") {
    font-size: 18px;
  }
}

h2 {
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  margin: 0;
  padding: 0;
  @include media-min("tablet") {
    font-size: 14px;
  }
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
  padding: 0;
  @include media-min("tablet") {
    font-size: 14px;
  }
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  margin: 0;
  padding: 0;
  @include media-min("tablet") {
    font-size: 12px;
  }
}

button {
  background-color: $transparent;
  border: none;
  outline: none;
}
