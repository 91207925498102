

.uiTextArea {
  width: calc(100% - 20px);
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.2);
  height: 40vh;
  padding: 10px;
}
