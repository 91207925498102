.widgetFormIncident {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-right: auto;
    margin-left: auto;

    &_validation {
        font-weight: 700;
        font-size: 16px;
        color: green;
    }

    &_count {
        align-self: flex-end;
        font-family: "source Sans Pro";
        font-weight: 400;
        font-size: 11px;
        margin-top: 15px;
    }

    &_dsc {
        text-align: center;
        font-family: "source Sans Pro";
        font-weight: 400;
        font-size: 13px;
        margin-top: 15px;
    }

    button {
        margin-top: 15px;
    }
}
