.reservations {
  padding: 0 10px;
}

.myReservations {
  &_text {
    margin: 40px auto;
    text-align: center;
  }
}

.myCommands {
  &_text {
    margin: 40px auto;
    text-align: center;
  }
}