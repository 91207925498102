@import '../../styles/colors.scss';
@import '../../styles/mixin';

.login {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.circleBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 45vh;
  &_green {
    width: 90vw;
    height: 90vw;
    border-radius: 50%;
    background: radial-gradient(
      circle at 100% 18%,
      rgba(253, 255, 255, 1) 0%,
      rgba(231, 243, 104, 1) 100%
    );
    filter: blur(10px);
    position: absolute;
    top: 14vh;
    left: -57vw;
  }
  &_red {
    width: 90vw;
    height: 90vw;
    border-radius: 50%;
    background: radial-gradient(
      circle at 48% 100%,
      rgba(253, 255, 255, 1) 0%,
      rgba(229, 43, 43, 1) 100%
    );
    mix-blend-mode: darken;
    filter: blur(10px);
    opacity: 0.8;
    position: absolute;
    z-index: 1;
  }
  &_transparent {
    width: 160vw;
    height: 160vw;
    border-radius: 50%;
    background-color: $white;
    opacity: 0.3;
    position: absolute;
    top: 18vh;
    z-index: 2;
    left: -65vw;
  }
}
.secondContainer {
  width: 100%;
  background: linear-gradient(to top, white 90%, transparent);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 15px;
  z-index: 5;
  height: 100%;
  @include media-min('tablet') {
    background: white;
  }
}

.loginContainer {
  @media (max-height: 400px) {
    margin-top: 3vh;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: 18vh;
  animation-name: AppearOpacity;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  &_inputs {
    background-color: $white;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    margin-bottom: 20px;
    width: 95%;
    position: relative;
    z-index: 3;
    margin-top: 10px;
    @include media-min('tablet') {
      height: 150px;
    }
  }

  &_forgotPassword {
    font-size: 10px;
    color: $black;
    text-decoration-line: none;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  &_buttons {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.logoLogifirm-login {
  width: 68px;
  height: auto;
  margin-bottom: 10vh;
  @include media-min('tablet') {
    width: 75px;
  }
}

@keyframes TopToBottom {
  0% {
    bottom: 20vh;
  }
  100% {
    bottom: 0vh;
  }
}

@keyframes AppearOpacity {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
