@import "../../../styles/colors.scss";
@import "../../../styles/mixin";

.uiServicesRate {
  margin-top: 15px;

  &_onePrice {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  &_title {
    font-weight: 600;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    margin-bottom: 15px;
    @include media-min("tablet") {
      font-size: 14px;
  }
  }

  &_name {
    font-weight: 400;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    @include media-min("tablet") {
      font-size: 14px;
  }
  }

  &_cost {
    font-weight: 700;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    @include media-min("tablet") {
      font-size: 14px;
  }
  }

  &_list {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &_space {
    margin-left: 5px;
    margin-right: 5px;
  }

  &_table {
    border-collapse: collapse;
    width: 100%;
    font-size: 16px;
    th:not(:last-child) {
      text-align: start;
    }
    tbody {
      tr:not(:last-child){
        border-bottom: solid 1px $gray-light;
      }
    }
    .quantity {
      display: flex;
      justify-content: center;
    }
  }

  &_total {
    font-size: 20px;
    span {
      font-weight: bold;
    }
    span.totalvat {
      font-style: italic;
      font-weight: normal;
      font-size:0.9em;
    }
  }
}

.productQuantity {
  padding: 20px;
}
