@import '../../styles/colors.scss';
@import '../../styles/mixin';

.myResidence {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto 25px;
  padding: 10px;
  &_icon {
    height: 50px;
    margin: auto 20px;
  }

  &_informations {
    &_title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
      color: $green;
    }
    &_text {
      font-weight: normal;
      margin-bottom: 10px;
      color: $green;
      &-bold {
        margin-bottom: 3px;
        font-weight: 600;
      }
    }
  }
}

.residenceImage {
  max-width: 150px;
  max-height: 150px;
  margin-left: 10px;
}
