@import "../../../styles/colors.scss";
@import "../../../styles/mixin";

.widgetServiceDisponibility {
  margin-top: 15px;

  &_title {
    font-weight: 600;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    margin-bottom: 15px;
    @include media-min("tablet") {
      font-size: 18px;
  }
  }

  &_textarea {
    margin-bottom: 20px;
  }

  &_warning {
    display: flex;
    margin: 0 30px 20px;
    align-items: center;

    p {
      font-weight: bold;
      padding-left: 10px;
    }
  }

  &_mail {
    margin-bottom: 20px;
  }

  &_commentContainer {
    margin-bottom: 50px;

    .react-datepicker-wrapper input {
      width: calc(100% - 20px);
      border-radius: 4px;
      border: 1px solid transparent;
      box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.2);
      height: 15px;
      padding: 10px;
      margin-bottom: 20px;
    }
  }

  &_confirmation {
    margin-top: 30px;
    margin-bottom: 50px;
    font-weight: bold;
  }
}
