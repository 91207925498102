@import '../../styles/colors.scss';
@import "../../styles/mixin";

.serviceCategory {
  padding: 8px 14px 16px 10px;

  &_open {
    background: $white;
    box-shadow: inset 0px 2px 4px rgba(72, 81, 86, 0.2);
    border-radius: 5px;
    padding: 15px;
    width: 100%;
  }
}

.serviceCategoryOpen {
  &_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  &_title {
    font-weight: 800;
    font-size: 22px;
    position: relative;
    top: 2px;
    color: $dark-blue;
    @include media-min("tablet") {
      font-size: 18px;
    }
  }

  &_services {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include media-min("tablet") {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-min("desktop-s") {
      grid-template-columns: repeat(8, 1fr);
    }
  }
}
