@import '../../styles/colors.scss';
@import "../../styles/mixin";

.myResidence {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto 25px;
  padding: 10px;
  &_icon {
    height: 50px;
    margin: auto 20px;
  }

  &_informations {
    &_text {
      font-weight: normal;
      margin-bottom: 10px;
      color: $green;
      &-bold {
        margin-bottom: 3px;
        font-weight: 600;
      }
    }
  }
}

.personalPreferences {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 5px;
  margin-left: auto;
  margin-right: auto;

  &_title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color:black;
    font-family: 'Montserrat', sans-serif;
    @include media-min("tablet") {
      font-size: 16px;
  }
  }

  :nth-child(n) {
    margin-bottom: 10px;
  }
}

.confirmDeleteAccount {
  height: 140px;
  margin-bottom: 10px;
  z-index: 2;
  position: relative;
  background-color: $red;
  box-shadow: 0px -2px 4px rgba(231, 51, 49, 0.1);
  border-radius: 6px 6px 0 0;
  padding-top: 10px;
  padding: 8px 16px;

  animation-name: BottomToTop;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  &_title {
    text-transform: uppercase;
    color: $white;
    font-weight: 900;
    font-size: 10px;
    text-align: center;
    margin-bottom: 10px;
    @include media-min("tablet") {
      font-size: 12px;
  }
  }

  &_informations {
    color: $white;
    font-size: 10px;
    text-align: center;
    width: 80%;
    margin: 0 auto 25px;
    @include media-min("tablet") {
      font-size: 12px;
  }
  }
}

@keyframes BottomToTop {
  0% {
    bottom: -200px;
  }
  100% {
    bottom: 0px;
  }
}
