.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fullCentered {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
