@import '../../styles/colors.scss';
@import "../../styles/mixin";

.home {
  padding: 0 10px;
  background: linear-gradient(
    180deg,
    transparent 60%,
    rgba(211, 211, 211, 0.1),
    rgba(211, 211, 211, 0.2),
    rgba(211, 211, 211, 0.3)
  );
  min-height: 100vh;

  &_circleBackground {
    overflow: hidden;

    &--green {
      opacity: 0.2;
      position: relative;
      left: 5vw;
      top: -10vh;
      width: 40vw;
      height: 40vw;
      border-radius: 50%;
      background-color: $green;
    }

    &--red {
      width: 40vw;
      height: 40vw;
      border-radius: 50%;
      background-color: $red;
      position: relative;
      top: 7vw;
      left: 20vw;
      mix-blend-mode: darken;
    }
  }
}

.interogation {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: $dark-green;
  @include media-min("tablet") {
    width: 70px;
    height: 70px;
  }

  svg {
    font-size: 50px;
    color: $white;
  }
}

.interogationOpen {
  background-color: $dark-green;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  width: 95%;
  height: 200px;
  margin-bottom: 12px;
  z-index: 5;

  animation-name: BottomToTop;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  &_text {
    color: $white;
    font-weight: 900;
    font-size: 10px;
    margin-top: 20px;
    padding-left: 15%;
  }

  &_button {
    padding: 8px 16px;
  }

  &_button:first-child {
    margin-top: 35px;
  }

  .interogation {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    @include media-min("tablet") {
      width: 50px;
      height: 50px;
    }

    svg {
      font-size: 30px;
      color: $dark-green;
    }
  }
}

.notifications {
  position: relative;
}

@keyframes BottomToTop {
  0% {
    bottom: -200px;
  }

  100% {
    bottom: 0px;
  }
}
