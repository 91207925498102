@import '../../../styles/colors.scss';
@import "../../../styles/mixin";

.widgetAccordionFaq {
  display: flex;
  justify-content: center;
  &_item {
    width: 95%;
  }
  &_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: $white;
    border-radius: 6px;
    border: 1px solid $transparent;
    box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.2);
    margin-bottom: 1vh;
    font-family: 'source Sans Pro';
    font-size: 14px;
    font-weight: 600;
    color: $gray-input;
    border-left-color: $green;
    border-left-width: 7px;
    @include media-min("tablet") {
      font-size: 16px;
  }
  }

  &_title,
  &_content {
    padding: 1rem;
    font-family: 'source Sans Pro';
    font-size: 14px;
    font-weight: 400;
    @include media-min("tablet") {
      font-size: 16px;
  }
  }
}
