.payment_content {
  min-width: 245px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_element {
  margin-bottom: 10px;
}

.submit_button {
  margin-bottom: 10px;
}
