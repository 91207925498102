@import "../../../styles/colors.scss";

.comeBackButton {
    display: flex;
    flex-direction: row;
    padding: 20px 10px;

    &_text {
        color: $black;
        margin-left: 10px;
        font-size: 16px; 
    }
}