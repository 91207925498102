@import '../../styles/colors.scss';
@import '../../styles/mixin';

.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  justify-content: space-between;

  .circleBackground-landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 78vh;
    &_green {
      width: 90vw;
      height: 90vw;
      border-radius: 50%;
      background-color: $green;
      filter: blur(10px);
      position: absolute;
      top: 20vh;
      left: -15vw;
      @include media-min('tablet') {
        width: 70vw;
        height: 70vw;
      }
    }
    &_red {
      width: 90vw;
      height: 90vw;
      border-radius: 50%;
      background-color: $red;
      mix-blend-mode: darken;
      filter: blur(10px);
      opacity: 0.8;
      position: absolute;
      z-index: 1;
      @include media-min('tablet') {
        width: 70vw;
        height: 70vw;
      }
    }
    &_transparent {
      width: 160vw;
      height: 160vw;
      border-radius: 50%;
      background-color: $white;
      opacity: 0.3;
      position: absolute;
      top: 6vh;
      z-index: 2;
      left: -8vw;
      @include media-min('tablet') {
        top: 16vh;
      }
    }
  }
}

.logoLogifirm {
  width: 100px;
  @include media-min('tablet') {
    width: 150px;
    height: auto;
  }
}

.landingInformations {
  position: absolute;
  top: 26%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 38px;
  z-index: 5;
  @include media-min('tablet') {
    top: 30%;
    padding-right: 60vw;
  }

  &_portal {
    color: $black;
    font-size: 14px;
    font-weight: 700;
    margin-top: 15px;
    @include media-min('tablet') {
      font-size: 16px;
    }
  }
}
.landingInformations_buttons {
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 4;
  width: 90%;
  margin-bottom: 3.5vh;
}
