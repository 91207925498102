@import "../../../styles/colors.scss";
@import "../../../styles/mixin";
.uiServiceName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_title {
        font-weight: 700;
        font-size: 20px;
        @include media-min("tablet") {
            font-size: 22px;
        }
    }

    &_iconContainer {
        display: flex;
        justify-content: center;
        background: #FFFFFF;
        align-items: center;
        box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.1);
        border-radius: 5px;
        width: 70px;
        height: 70px;
        @include media-min("tablet") {
            width: 80px;
            height: 80px;
        }

        img {
            width: 50px;
            object-fit: cover;
            @include media-min("tablet") {
                width: 60px;
            }
        }
    }
}
