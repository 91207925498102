@import "../../../styles/colors.scss";
@import "../../../styles/mixin";

.uiServiceDescription {
  margin-top: 5px;
  margin-bottom: 30px;

  &_title {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Source Sans Pro';
    @include media-min("tablet") {
      font-size: 20px;
  }
  }

  &_description {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Source Sans Pro';
    white-space: pre-line;
    @include media-min("tablet") {
      font-size: 16px;
  }
  }

  &_descriptionContainer {
    margin-top: 5px;
  }
}
