@import '../../styles/colors.scss';
@import '../../styles/mixin';

.notification {
  min-height: 30px;
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include media-min('tablet') {
    min-height: 40px;
  }

  &_text {
    width: 90%;
    min-height: 30px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.2);
    background: #ffffff;
    border-radius: 5px;
    @include media-min('tablet') {
      min-height: 40px;
    }
  }

  &_desc {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    padding: 5px 10px;
  }

  &_hour {
    font-style: italic;
    font-size: 14px;
    color: $orange;
    margin-bottom: 5px;

    @include media-min('tablet') {
      font-size: 16px;
    }
  }

  &_title {
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 5px;
    @include media-min('tablet') {
      font-size: 18px;
    }
  }

  &_infocontainer {
    width: 50px;
  }

  &_info {
    position: relative;
    top: 3px;
    width:35px;
  }

  &_close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.notificationOpen {
  display: flex;
  flex-direction: row;
  gap: 4px;
  &_text {
    padding: 0 10px 10px;
    font-size: 16px;
  }
}

.close {
  width: 20px;
  height: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.2);

  background: rgba(72, 81, 86, 0.5);
  border-radius: 5px;

  &_img {
    position: relative;
    top: 1px;
  }
}

.notificationsImage {
  width: 70px;
  height: 70px;
}
