$breakpoints: ("mobil": 500px,
    "tablet": 768px,
    "desktop-s": 1024px,
    "desktop-m": 1440px,
    "desktop-l": 1680px);


@mixin media-min($_key) {
    @media screen and (min-width: map-get($breakpoints, $_key)) {
        & {
            @content;
        }
    }
}

@mixin media-max($_key) {
    @media screen and (max-width: map-get($breakpoints, $_key)) {
        & {
            @content;
        }
    }
}