@import '../../../styles/colors.scss';
@import "../../../styles/mixin";

.uiButton {
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include media-min("tablet") {
    font-size: 16px;
  }

  &:hover {
    cursor: pointer;
    border-color: grey;
  }
  &:focus {
    border-color: grey;
  }
  &:active {
    color: lightgrey;
  }
  &:disabled {
    color: $gray-light;
    opacity: 0.5;
    box-shadow: inset 0px 2px 4px rgba(72, 81, 86, 0.1);
    cursor: not-allowed;
  }

  &--secondary {
    box-shadow: none;
  }

  &--small {
    width: 80px;
    height: 40px;
    @include media-min("tablet") {
      width: 90px;
      height: 50px;
    }
  
  }

  &--medium {
    width: 130px;
    height: 40px;
    @include media-min("tablet") {
      width: 140px;
      height: 50px;
    }
  }

  &--large {
    width: 260px;
    height: 40px;
    @include media-min("tablet") {
      width: 270px;
      height: 50px;
    }
  }

  &--full {
    width: 100%;
    height: 40px;
    @include media-min("tablet") {
      height: 50px;
    }
  }

  &--bgGreen {
    background-color: $dark-green;
  }

  &--bgRed {
    background-color: $red;
  }

  &--bgGray {
    background-color: $gray-secondary;
  }

  &--bgWhite {
    background-color: $white;
  }

  &--bgTransparent {
    background-color: $transparent;
  }
  &--bgBlue {
    background-color: $blue-actif;
  }

  &--black {
    color: $black;
  }

  &--white {
    color: $white;
  }

  &--green {
    color: $dark-green;
  }

  &--red {
    color: $red;
  }
  &--gray {
    color: $gray-input;
  }
}
