@import '../../../styles/colors.scss';
@import "../../../styles/mixin";

.uiInputNumber {
  border-radius: 6px;
  display: inline-block;
  text-align: left;

  &_label {
    width: max-content;
    padding: 2px 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 7px;
    color: $gray;
    position: relative;
    top: 7px;
    left: 15px;
    background-color: $white;
  }

  &_field {
    box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.2);
    background-color: $white;
    background: $white;
    height: 40px;
    width: 30px;
    font-size: 16px;
    border: 1px solid $gray;
    border-radius: 6px;
    padding: 0 10px;
    outline: none;
    @include media-max("tablet") {
      width: 12px;
    }

    &::placeholder {
      font-size: 13px;
      color: $gray;
    }

    &:focus {
      border: 1px solid $blue-actif;
      box-shadow: 0px 2px 4px rgba(50, 160, 230, 0.1);
    }
  }

  &_button {
      font-weight: bold;
      font-size: 20px;
      padding: 20px 15px;
  }
}
