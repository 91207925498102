@import '../../../styles/colors.scss';
@import '../../../styles/mixin';

.container {
  margin-top: 7.47px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 90px;
  border-radius: 5px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
    0px 4px 20px -2px rgba(50, 50, 71, 0.08);
}

.link {
  text-decoration: none;
}

.residentImage {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  max-width: 130px;
}

.redidentDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resident_location {
  &_city {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    color: #8ba800;
  }
  &_address {
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    color: #485156;
  }
}
