.resetPasswordContainer {
  margin-top: 5vh;
  width: 80vw;
  margin-left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  animation-name: Appear;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  &_back {
    position: absolute;
    top: 0;
    left: 0;
  }

  &_forgotPassword {
    position: relative;
    width: 68px;
    height: auto;
    margin-bottom: 10vh;
  }

  &_input {
    margin-bottom: 10px;
    width: 100%;
  }

  &_text {
    text-align: center;
    margin-bottom: 35px;
  }

  &_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .uiInput_error {
      margin-top: 10px;
      display: inline-block;
      margin-bottom: 10px;
    }
  }
}
