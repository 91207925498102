@import '../../../styles/colors.scss';
@import "../../../styles/mixin";

.openHeader {
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 105vh;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(72, 81, 86, 0.9) 64.06%, rgba(0, 0, 0, 0.9) 100%);

  animation-name: Appear;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  @include media-min("tablet") {
    font-size: 30px;
  }

  &_hello {
    font-weight: bold;
    font-size: 24px;
    position: relative;
    top: 4vh;
    text-align: center;
    color: #fff;
    margin-bottom: 80px;
    @include media-min("tablet") {
      font-size: 34px;
    }

    &--name {
      color: #BCCF00;
    }
  }

  &_close {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  &_cross {
    font-size: 34px;
    color: white;
    float: right;
    padding-right: 20px;
    font-weight: 700;
    @include media-min("tablet") {
    font-size: 44px;
    }
  }

  &_menu {
    height: 60vh;
    overflow: hidden;
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &_text {
    font-weight: 600;
    font-size: 22px;
    color: $white;
    text-align: start;
    text-decoration: none;
    @include media-min("tablet") {
      font-size: 34px;
    }
  }

  &_link {
    width: 90vw;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    margin-left: 7vw;
  }

  &_icon {
    margin-right: 15px;
    width: 24px;
    @include media-min("tablet") {
      width: 32px;
      margin-right: 25px;
    }
  }

  &_power {
    margin-right: 15px;
  }

  &_deconection {
    position: absolute;
    left: 0;
    bottom: 15vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_deco {
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &_deconect {
    font-weight: 600;
    font-size: 18px;
    color: $white;
    text-align: center;
    text-transform: uppercase;
    @include media-min("tablet") {
      font-size: 20px;
      }
  }
}

.miniHeader {
  height: 64px;
  padding: 0 0 0 10px;
  display: flex;
  margin: 0 -10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow:   0px 2px 4px rgba(72, 81, 86, 0.1);
  border-bottom: 1px solid transparent;
  background-color: #FAFAFA;
  z-index: 10;
  overflow: hidden;
  @include media-min("tablet") {
    height: 84px;
  }

  &_logo {
    width: 55px;
    @include media-min("tablet") {
      width: 65px;
    }
  }

  &_burger {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    @include media-min("tablet") {
      width: 60px;
      height: 60px;
    }
  }

  &_titleButton {
    height: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 1vw;

    &_title {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      line-height: 18px;
    }

    &_baseline {
      font-size: 9px;
      font-style: italic;
      color: #fff;
    }
  }

  &_withoutBoxShadow {
    box-shadow: none;
  }

  &_logoLogifirm {
    width: 26px;

    &_radius {
      border-radius: 6px;
      width: 26px;
    }
  }
}
.header_kebab {
    width: 30px;
    height: 30px;
}

@keyframes Appear {
  0% {
    height: 0vh;
  }

  100% {
    height: 100vh;
  }
}
