@import '../../styles/colors.scss';
@import "../../styles/mixin";

.serviceCard {
  background: $white;
  box-shadow: 0px 2px 2px rgba(72, 81, 86, 0.2);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 10px 5px 0px;
  text-decoration: none;
  color: $black;
  position: relative;

  &_disabled {
    color: $gray-input;
  }

  &:visited {
    text-decoration: none;
    color: $black;
  }

  &:hover {
    text-decoration: none;
    color: $black;
  }

  &:focus {
    text-decoration: none;
    color: $black;
  }

  &_icon {
    width: 60px;
    height: 60px;
    margin-bottom: 12px;
    @include media-min("tablet") {
      width: 80px;
      height: 80px;
    }

    &_disabled {
      opacity: 0.2;
    }
  }

  &_label {
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 40px;
    position: relative;
    line-height: 20px;
    @include media-min("tablet") {
      font-size: 17px;
    }
  }
}
