@import "../../styles/colors.scss";
@import "../../styles/mixin";

.faq {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &_accordion {
    margin-top: 5vh;
    overflow-y: scroll;
    height: 60vh;
  }
  &_btn {
    margin: 10px;
  }
  &_dsc {
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    font-family: 'Source Sans Pro';
    @include media-min("tablet") {
      font-size: 13px;
  }
  }
}
