@import "../../../styles/colors.scss";
@import "../../../styles/mixin";

.UISelect {
  position: relative;
  margin-bottom: 0;

  &_svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 3px);
    width: 10px;
    height: 6px;
    stroke-width: 2px;
    stroke: black;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    pointer-events: none;
  }

  &_select {
    width: 100%;
    height: 40px;

    font-family: inherit;
    font-size: 16px;
    color: $black;

    padding: 0 10px;
    margin-bottom: 0;
    border: none;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(72, 81, 86, 0.2);
    @include media-min("tablet") {
      height: 50px;
  }

    cursor: pointer;
    transition: all 150ms ease;
    -webkit-appearance: none;

    &:focus {
      outline: none;
      border: none;
    }

    &:disabled {
      border: 2px solid $gray;
    }
  
    &__options {
      color: $black;
      &[value=""][disabled] {
        display: none
      }
  
      &:focus {
        outline: none;
      }
  
      &:hover + svg {
        stroke: black
      }
    }
  }

  &--medium {
    width: 110px;
    @include media-min("tablet") {
      width: 120px;
  }
  }

  &--full {
    width: 100%;
  }
}
