.services {
  &_headerImg {
    position: absolute;
    width: 100%;
    height: 112px;
    object-fit: cover;
  }

  &_headerContainer {
    position: relative;
    height: 112px;
    margin-bottom: 15px;
  }

  &_headerContent {
    position: relative;
    top: 25%;
  }

  &_mainContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
}
