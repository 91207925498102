@import "../../../styles/colors.scss";
@import "../../../styles/mixin";

.uiHeaderForm {
    height: 6vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &_title {
        font-weight: 900;
        font-size: 20px;
        @include media-min("tablet") {
            font-size: 22px;
        }
    }

    &_subTitle {
        font-family: 'Source Sans Pro';
        font-weight: 400;
        font-size: 12px;
        @include media-min("tablet") {
            font-size: 14px;
        }
    }
}
