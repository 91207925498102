@import '../../styles/colors.scss';
@import '../../styles/mixin';
.personalInformations {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 35px;

  &_title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 12px;
    color: $black;
    @include media-min('tablet') {
      font-size: 16px;
    }
  }

  &_input {
    margin-bottom: 10px;
    height: 10px;
    color: $black;
  }

  &_error {
    text-align: center;
    padding-top: 10px;
    color: red;
    font-size: 14px;
    @include media-min('tablet') {
      font-size: 16px;
    }
  }

  &_submitOk {
    text-align: center;
    padding-top: 10px;
    color: green;
    font-size: 14px;
    @include media-min('tablet') {
      font-size: 16px;
    }
  }
}

.downloadButton {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.buttonText {
  color: #485156;
  font-weight: 700;
  font-size: 12px;
}

.downloadButtonContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
